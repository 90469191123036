import { SyphilisExaminationCard } from 'features/outpatientCard/types/syphilisExaminationTypes';
import { Dictionary } from 'entities/dictionary';
import { FieldType, FormConstructorModel } from '../../../form/types/types';

export const syphilisExaminationForm: FormConstructorModel = {
  rootEntityName: 'outpatientCards',
  entityName: 'outpatientCard',
  formEntityName: 'syphilisExaminationForm',
  disabledCondition: {
    name: ['card', 0, 'notSuitable'],
    value: true,
  },
  cards: [
    {
      id: 'card',
      key: 'notSuitable',
      title: '',
      fields: [
        {
          id: 'notSuitable',
          title: 'Не применимо',
          type: FieldType.Checkbox,
          name: 'notSuitable',
        },
      ],
    },
    {
      id: 'card',
      key: 'patientComplaints',
      title: 'Жалобы больного',
      columnCount: 2,
      isDisabledCondition: true,
      fields: [
        {
          id: 'decreasedVision',
          title: 'Cнижение зрения',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'decreasedVision',
        },
        {
          id: 'decreasedHearing',
          title: 'Cнижение слуха',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'decreasedHearing',
        },
        {
          id: 'decreasedMemory',
          title: 'Cнижение памяти',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'decreasedMemory',
        },
        {
          id: 'incoordination',
          title: 'Нарушение координации движения',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'incoordination',
        },
      ],
    },
    {
      id: 'card',
      key: 'externalInspection',
      title: 'Наружный осмотр В/ч головы: наличие',
      columnCount: 2,
      isDisabledCondition: true,
      fields: [
        {
          id: 'hairAllopecia',
          title: 'Аллопеций',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'hairAllopecia',
        },
        {
          id: 'hairPapula',
          title: 'Папулы',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'hairPapula',
        },
        {
          id: 'hairPustula',
          title: 'Пустулы',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'hairPustula',
        },
        {
          id: 'hairCrust',
          title: 'Корочки',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'hairCrust',
        },
      ],
    },
    {
      id: 'card',
      key: 'tongue',
      title: 'Слизистая полости рта, язык',
      columnCount: 2,
      isDisabledCondition: true,
      fields: [
        {
          id: 'tongueSpots',
          title: 'Пятна',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'tongueSpots',
        },
        {
          id: 'tonguePapula',
          title: 'Папулы',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'tonguePapula',
        },
        {
          id: 'tongueErosion',
          title: 'Эрозии',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'tongueErosion',
        },
        {
          id: 'tongueUlcers',
          title: 'Язвы',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'tongueUlcers',
        },
        {
          id: 'tongueAngina',
          title: 'Ангина',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'tongueAngina',
        },
      ],
    },
    {
      id: 'card',
      key: 'lips',
      title: 'Cостояние губ',
      columnCount: 2,
      isDisabledCondition: true,
      fields: [
        {
          id: 'lipsPapula',
          title: 'Папулы',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'lipsPapula',
        },
        {
          id: 'lipsJammed',
          title: 'Заеды',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'lipsJammed',
        },
        {
          id: 'lipsErosion',
          title: 'Эрозии',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'lipsErosion',
        },
        {
          id: 'voiceHoarseness',
          title: 'Осиплость голоса',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'voiceHoarseness',
        },
      ],
    },
    {
      id: 'card',
      key: 'lymph',
      title: 'Пальпация лимфатических узлов',
      columnCount: 2,
      isDisabledCondition: true,
      fields: [
        {
          id: 'cervicalLymph',
          title: 'Шейные',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'cervicalLymph',
        },
        {
          id: 'submandibularLymph',
          title: 'Подчелюстные',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'submandibularLymph',
        },
        {
          id: 'axillaryLymph',
          title: 'Подмышечные',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'axillaryLymph',
        },
        {
          id: 'elbowLymph',
          title: 'Локтевые',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'elbowLymph',
        },
        {
          id: 'inguinalLymph',
          title: 'Паховые',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'inguinalLymph',
        },
      ],
    },
    {
      id: 'card',
      key: 'bone',
      title: 'Наличие костных диструкций',
      columnCount: 2,
      isDisabledCondition: true,
      fields: [
        {
          id: 'boneSaddleNose',
          title: 'Седловидный нос',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'boneSaddleNose',
        },
        {
          id: 'boneHighGothicPalate',
          title: 'Высокое готическое нёбо',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'boneHighGothicPalate',
        },
        {
          id: 'boneHutchinsonTeeth',
          title: 'Зубы Гетчинсона',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'boneHutchinsonTeeth',
        },
        {
          id: 'boneXiphoidProcess',
          title: 'Отсутствие мечевидного отростка',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'boneXiphoidProcess',
        },
        {
          id: 'boneSaberTibia',
          title: 'Саблевидные голени',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'boneSaberTibia',
        },
      ],
    },
    {
      id: 'card',
      key: 'bone',
      title: 'Осмотр гениталий и перианальной области (для врачей акушеров гинекологов, урологов, проктологов)',
      columnCount: 2,
      isDisabledCondition: true,
      fields: [
        {
          id: 'genitaliaSpots',
          title: 'Пятна',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'genitaliaSpots',
        },
        {
          id: 'genitaliaPapula',
          title: 'Папулы',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'genitaliaPapula',
        },
        {
          id: 'genitaliaErosion',
          title: 'Эрозии',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'genitaliaErosion',
        },
        {
          id: 'genitaliaUlcers',
          title: 'Язвы',
          type: FieldType.DictionaryRadioGroup,
          dictionaryName: Dictionary.YesNoNotSuitable,
          name: 'genitaliaUlcers',
        },
      ],
    },
    {
      id: 'card',
      key: 'doctor',
      title: 'Врач',
      isDisabledCondition: true,
      fields: [
        {
          id: 'surnameAgreementOperator',
          title: 'Фамилия полностью',
          type: FieldType.Input,
          name: 'surnameAgreementOperator',
        },
        {
          id: 'nameAgreementOperator',
          title: 'Имя полностью',
          type: FieldType.Input,
          name: 'nameAgreementOperator',
        },
        {
          id: 'patronymicAgreementOperator',
          title: 'Отчество полностью',
          type: FieldType.Input,
          name: 'patronymicAgreementOperator',
        },
      ],
    },
  ],
};
// todo: порефакторить
export const initialSyphilisExamination: SyphilisExaminationCard = {
  decreasedVision: false,
  decreasedHearing: false,
  decreasedMemory: false,
  incoordination: false,
  hairAllopecia: false,
  hairPapula: false,
  hairPustula: false,
  hairCrust: false,
  tongueSpots: false,
  tonguePapula: false,
  tongueErosion: false,
  tongueUlcers: false,
  tongueAngina: false,
  lipsPapula: false,
  lipsJammed: false,
  lipsErosion: false,
  voiceHoarseness: false,
  cervicalLymph: false,
  submandibularLymph: false,
  axillaryLymph: false,
  elbowLymph: false,
  inguinalLymph: false,
  boneSaddleNose: false,
  boneHighGothicPalate: false,
  boneHutchinsonTeeth: false,
  boneXiphoidProcess: false,
  boneSaberTibia: false,
  genitaliaSpots: false,
  genitaliaPapula: false,
  genitaliaErosion: false,
  genitaliaUlcers: false,
};
