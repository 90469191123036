import { Dictionary } from 'entities/dictionary';
import { hideField } from 'features/form';
import { Mask } from 'features/mask';
import { FieldType, FormConstructorModel } from '../../../form/types/types';

export const outPatientCardMainInfoForm: FormConstructorModel = {
  rootEntityName: 'outpatientCards',
  entityName: 'outpatientCard',
  formEntityName: 'outpatientMainInfoForm',
  cards:
    [
      {
        id: 'mainInfo',
        key: 'mainInfo',
        title: 'Основная информация',
        fields: [
          {
            id: 'surName',
            title: 'Фамилия',
            type: FieldType.Input,
            name: 'surName',
            rules: [{ required: true }],
          },
          {
            id: 'firstName',
            title: 'Имя',
            type: FieldType.Input,
            name: 'firstName',
            rules: [{ required: true }],
          },
          {
            id: 'patronymic',
            title: 'Отчество',
            type: FieldType.Input,
            name: 'patronymic',
          },
          {
            id: 'createDate',
            title: 'Дата заполнения медицинской карты',
            type: FieldType.DatePicker,
            name: 'createDate',
            rules: [{ required: true }],
          },
          {
            id: 'sex',
            title: 'Пол',
            type: FieldType.DictionaryRadioGroup,
            dictionaryName: Dictionary.Sex,
            optionType: 'button',
            name: 'sex',
          },
          {
            id: 'birthDate',
            title: 'Дата рождения',
            type: FieldType.DatePicker,
            name: 'birthDate',
          },
          {
            id: 'chIseries',
            title: 'Полис ОМС - Серия',
            type: FieldType.Input,
            name: 'chIseries',
            mask: Mask.OmsSeria,
          },
          {
            id: 'chInumber',
            title: 'Полис ОМС - Номер',
            type: FieldType.Input,
            name: 'chInumber',
            mask: Mask.OmsNumber,
          },
          {
            id: 'snils',
            title: 'СНИЛС',
            type: FieldType.Input,
            name: 'snils',
            mask: Mask.Snils,
          },
          {
            id: 'insuranceOrganisationId',
            title: 'Наименование страховой медицинской организации',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.InsuranceOrganisation,
            name: 'insuranceOrganisationId',
          },
          {
            id: 'benefitCategoryCode',
            title: 'Код категории льготы',
            type: FieldType.Input,
            name: 'benefitCategoryCode',
            mask: Mask.BenefitCode,
          },
          {
            id: 'documentName',
            title: 'Документ - Название',
            type: FieldType.Input,
            name: 'documentName',
          },
          {
            id: 'documentSeries',
            title: 'Документ - Серия',
            type: FieldType.Input,
            name: 'documentSeries',
          },
          {
            id: 'documentNumber',
            title: 'Документ - Номер',
            type: FieldType.Input,
            name: 'documentNumber',
          },
        ],
      },
      {
        id: 'phones',
        key: 'phones',
        title: 'Телефоны',
        fieldsLayout: 'table',
        fields: [
          {
            id: 'phone',
            title: 'Номер телефона',
            type: FieldType.Input,
            name: 'phone',
            mask: Mask.Phone,
            rules: [{ required: true, message: 'Пожалуйста, введите номер телефона' }],
          },
          {
            id: 'comment',
            title: 'Комментарий',
            type: FieldType.Input,
            name: 'comment',
          },
          {
            id: 'remove',
            title: '',
            columnButton: 'remove',
          },
        ],
      },
      {
        id: 'mainInfo',
        key: 'address',
        title: 'Место жительства',
        fields: [
          {
            id: 'district',
            title: 'Район',
            type: FieldType.Input,
            name: 'district',
          },
          {
            id: 'city',
            title: 'Город',
            type: FieldType.Input,
            name: 'city',
          },
          {
            id: 'community',
            title: 'Населенный пункт',
            type: FieldType.Input,
            name: 'community',
          },
          {
            id: 'street',
            title: 'Улица',
            type: FieldType.Input,
            name: 'street',
          },
          {
            id: 'house',
            title: 'Дом',
            type: FieldType.Input,
            name: 'house',
          },
          {
            id: 'building',
            title: 'Корпус',
            type: FieldType.Input,
            name: 'building',
          },
          {
            id: 'flat',
            title: 'Квартира',
            type: FieldType.Input,
            name: 'flat',
          },
          {
            id: 'locality',
            title: 'Местность',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.Locality,
            name: 'locality',
          },
        ],
      },
      {
        id: 'headerInfo',
        key: 'headerInfo',
        title: '',
        fields: [
          {
            id: 'pediculosis',
            name: 'pediculosis',
            title: 'Педикулёз',
            dictionaryName: Dictionary.YesNo,
            type: FieldType.DictionaryRadioGroup,
            optionType: 'button',
          },
          {
            id: 'Scabies',
            name: 'Scabies',
            title: 'Часотка',
            dictionaryName: Dictionary.YesNo,
            type: FieldType.DictionaryRadioGroup,
            optionType: 'button',
          },
          {
            id: 'TBS',
            name: 'TBS',
            title: 'ТБС',
            dictionaryName: Dictionary.YesNo,
            type: FieldType.DictionaryRadioGroup,
            optionType: 'button',
          },
          {
            id: 'Hepatitis',
            name: 'Hepatitis',
            title: 'Гепатит',
            dictionaryName: Dictionary.YesNo,
            type: FieldType.DictionaryRadioGroup,
            optionType: 'button',
          },
          {
            id: 'venerealDisease',
            name: 'venerealDisease',
            title: 'Венерические заболевания',
            dictionaryName: Dictionary.YesNo,
            type: FieldType.DictionaryRadioGroup,
            optionType: 'button',
          },
        ],
      },
      {
        id: 'mainInfoPersonal',
        key: 'mainInfoPersonal',
        title: 'Персональная информация',
        fields: [
          {
            id: 'family',
            title: 'Семейное положение',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.Family,
            name: 'family',
          },
          {
            id: 'education',
            title: 'Образование',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.Education,
            name: 'education',
          },
          {
            id: 'work',
            title: 'Занятость',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.Work,
            name: 'work',
          },
          {
            id: 'disabilityType',
            title: 'Тип инвалидности',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.DisabilityType,
            name: 'disabilityType',
          },
          {
            id: 'disabilityDate',
            title: 'Дата',
            type: FieldType.DatePicker,
            name: 'disabilityDate',
          },
          {
            id: 'workPlace',
            title: 'Место работы',
            type: FieldType.Input,
            name: 'workPlace',
          },
          {
            id: 'workPosition',
            title: 'Должность',
            type: FieldType.Input,
            name: 'workPosition',
          },
          {
            id: 'workPlaceChange',
            title: 'Изменение места работы',
            type: FieldType.Input,
            name: 'workPlaceChange',
          },
          {
            id: 'registrationPlaceChange',
            title: 'Изменение места регистрации',
            type: FieldType.Input,
            name: 'registrationPlaceChange',
          },
          {
            id: 'bloodGroup',
            title: 'Группа крови',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.BloodGroup,
            name: 'bloodGroup',
          },
          {
            id: 'rhFactor',
            title: 'Резус фактор',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.RhFactor,
            name: 'rhFactor',
          },
          {
            id: 'allergicReactions',
            name: 'allergicReactions',
            title: 'Аллергические реакции',
            type: FieldType.Input,
          },
        ],
      },
      {
        id: 'additionalInfo',
        key: 'additionalInfo',
        title: 'Дополнительная информация',
        fields: [
          {
            id: 'race',
            name: 'race',
            title: 'Раса',
            type: FieldType.DictionarySelect,
            dictionaryName: Dictionary.Race,
          },
          {
            id: 'otherRace',
            name: 'otherRace',
            title: 'Раса',
            type: FieldType.Input,
            hidden: hideField('additionalInfo', 'race', 3),
          },
          {
            id: 'patientDate',
            name: 'patientDate',
            title: 'Дата',
            type: FieldType.DatePicker,
          },
          {
            id: 'patientDiagnosis',
            name: 'patientDiagnosis',
            title: 'Диагноз',
            type: FieldType.Input,
          },
          {
            id: 'patientPath',
            name: 'patientPath',
            title: 'Маршрут пациента',
            type: FieldType.Input,
          },
        ],
      },
      {
        id: 'deseases',
        key: 'deseases',
        title: 'Заболевания, по поводу которых осуществляется диспансерное наблюдение',
        fieldsLayout: 'table',
        fields: [
          {
            id: 'date',
            title: 'Дата начала диспансерного наблюдения',
            type: FieldType.DatePicker,
            name: 'date',
          },
          {
            id: 'endDate',
            title: 'Дата прекращения диспансерного наблюдения',
            type: FieldType.DatePicker,
            name: 'endDate',
          },
          {
            id: 'diagnosis',
            title: 'Диагноз',
            type: FieldType.Input,
            name: 'diagnosis',
          },
          {
            id: 'icbCode',
            title: 'Код по МКБ-10',
            type: FieldType.Input,
            name: 'icbCode',
            mask: Mask.MKB,
          },
          {
            id: 'doctor',
            title: 'Врач (Фамилия И.О.)',
            name: 'doctor',
            type: FieldType.Input,
          },
        ],
        columnCount: 5,
      },
      {
        id: 'diagnosis',
        key: 'diagnosis',
        title: 'Лист записи заключительных (уточненных) диагнозов',
        fieldsLayout: 'table',
        fields: [
          {
            id: 'date',
            title: 'Дата (число, месяц, год)',
            type: FieldType.DatePicker,
            name: 'date',
          },
          {
            id: 'text',
            title: 'Заключительные (уточненные) диагнозы',
            type: FieldType.Input,
            name: 'text',
          },
          {
            id: 'type',
            title: 'Установленные впервые или повторно (+/-)',
            type: FieldType.Input,
            name: 'type',
          },
          {
            id: 'doctor',
            title: 'Врач (Фамилия И.О.)',
            type: FieldType.Input,
            name: 'doctor',
          },
        ],
      },
    ],
};
