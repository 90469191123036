export const availableToAuthorizedUser = [
  'Администратор',
  'Исследователь',
  'Главный исследователь',
  'Монитор',
  'Дата менеджер',
  'Аудитор контроля качества',
  'Медицинский монитор',
  'Специалист по фармаконадзору',
];
